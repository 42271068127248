import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './actions';
import { expensesMap } from './functions/expenses-map';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,
  on(fromActions.resetState, () => fromState.initialState),
  on(fromActions.resetDetails, (state) => ({ ...state, details: null })),

  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { expenses, pagination }) => ({
    ...state,
    isLoading: false,
    expenses: expenses.map(expensesMap),
    pagination,
  })),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.loadDetailsRequest, (state) => ({
    ...state,
    error: null,
  })),
  on(fromActions.loadDetailsSuccess, (state, { details }) => ({
    ...state,
    details,
  })),
  on(fromActions.loadDetailsFailure, (state, { error }) => ({
    ...state,
    error,
  })),
);

export function expensesReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
