import { Injectable } from '@angular/core';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { ExpensesService } from '../../services';
import { NotificationService } from '../../ui/services/notification.service';
import { MediasStoreActions } from '../medias-store';

import * as fromActions from './actions';

@Injectable()
export class ExpensesStoreEffects {
  constructor(
    private dataService: ExpensesService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private translate: TranslateService,
    private notifications: NotificationService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ propertiesIds, httpParams }) =>
        this.dataService.load(propertiesIds, httpParams).pipe(
          map(({ data, meta }: IResponseSuccess) => {
            return fromActions.loadSuccess({
              expenses: data,
              pagination: meta.pagination,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  loadDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadDetailsRequest),
      switchMap(({ expenseId }) =>
        this.dataService.loadDetails(expenseId).pipe(
          map(({ data }: IResponseSuccess) => {
            return fromActions.loadDetailsSuccess({ details: data[0] });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadDetailsFailure(error));
          }),
        ),
      ),
    ),
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createRequest),
      switchMap(({ type, attach, ...payload }) =>
        this.dataService.create(payload).pipe(
          mergeMap(({ data }: IResponseSuccess) => {
            const { id: expenseId } = data[0];

            this.notifications.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('notifications.create_success', {
                param: this.translate.instant('expense'),
              }),
              type: 'success',
            });

            const effects: Action[] = [fromActions.createSuccess()];

            if (attach) {
              effects.push(
                new MediasStoreActions.CreateRequestAction({
                  image: attach,
                  type: 'expense',
                  type_id: expenseId,
                  disableNotify: true,
                }),
              );
            }

            return effects;
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.createFailure(error));
          }),
        ),
      ),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateRequest),
      switchMap(({ type, ...payload }) =>
        this.dataService.update(payload).pipe(
          map(() => {
            this.notifications.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('notifications.update_success', {
                param: this.translate.instant('expense'),
              }),
              type: 'success',
            });
            return fromActions.updateSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.updateFailure(error));
          }),
        ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteRequest),
      switchMap(({ expenseId }) =>
        this.dataService.delete(expenseId).pipe(
          map(() => {
            this.notifications.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('notifications.delete_success', {
                param: this.translate.instant('expense'),
              }),
              type: 'success',
            });
            return fromActions.deleteSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.deleteFailure(error));
          }),
        ),
      ),
    ),
  );
}
