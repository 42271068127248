import { IPagination } from '../../core/models/api/pagination/pagination.model';
import { Expense } from '../../models/objects/expense';

export interface State {
  isLoading?: boolean;
  error?: any;
  expenses: Partial<Expense>[];
  details: Expense;
  pagination: IPagination;
}

export const initialState: State = {
  isLoading: false,
  error: null,
  expenses: [],
  details: null,
  pagination: null,
};
