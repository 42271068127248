import { isNil } from 'lodash';

import { Expense } from '../../../models/objects/expense';

const getAccountHolder = (expense: Expense): string => {
  if (!expense) {
    return '';
  }

  const { company, customer_name, customer_surname } = expense;

  return company
    ? customer_surname
    : `${customer_surname || ''} ${customer_name || ''}`;
};

const getVatQuoteValue = (expense: Expense): string => {
  if (!expense) {
    return;
  }

  const { vat_quote_value } = expense;

  if (isNil(vat_quote_value)) {
    return 'N.D.';
  }

  return `${Math.trunc(+vat_quote_value)} %`;
};

export const expensesMap = (
  expense: Expense,
  index: number,
  expenses: Expense[],
) => {
  return {
    ...expense,
    accountholder: getAccountHolder(expense),
    vat_quote_value: getVatQuoteValue(expense),
  };
};
