import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const selectState = createFeatureSelector<State>('expenses');

export const selectError = createSelector(selectState, getError);

export const selectIsLoading = createSelector(selectState, getIsLoading);

export const selectExpenses = createSelector(
  selectState,
  (state: State) => state.expenses,
);

export const selectExpenseDetails = createSelector(
  selectState,
  (state: State) => state.details,
);

export const selectPagination = createSelector(
  selectState,
  (state: State) => state.pagination,
);
