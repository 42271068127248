import { HttpParams } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { IPagination } from '../../core/models/api/pagination/pagination.model';
import { CreateExpenseRequest, UpdateExpenseRequest } from '../../models';
import { Expense } from '../../models/objects/expense';

export const loadRequest = createAction(
  '[Expenses] Load Request',
  props<{ propertiesIds: number[]; httpParams: HttpParams }>(),
);
export const loadSuccess = createAction(
  '[Expenses] Load Success',
  props<{ expenses: Partial<Expense>[]; pagination: IPagination }>(),
);
export const loadFailure = createAction(
  '[Expenses] Load Failure',
  props<{ error: any }>(),
);

export const loadDetailsRequest = createAction(
  '[Expenses] Load Details Request',
  props<{ expenseId: number }>(),
);
export const loadDetailsSuccess = createAction(
  '[Expenses] Load Details Success',
  props<{ details: Expense }>(),
);
export const loadDetailsFailure = createAction(
  '[Expenses] Load Details Failure',
  props<{ error: any }>(),
);

export const createRequest = createAction(
  '[Expenses] Create Request',
  props<CreateExpenseRequest>(),
);
export const createSuccess = createAction('[Expenses] Create Success');
export const createFailure = createAction(
  '[Expenses] Create Failure',
  props<{ error: any }>(),
);

export const updateRequest = createAction(
  '[Expenses] Update Request',
  props<UpdateExpenseRequest>(),
);
export const updateSuccess = createAction('[Expenses] Update Success');
export const updateFailure = createAction(
  '[Expenses] Update Failure',
  props<{ error: any }>(),
);

export const deleteRequest = createAction(
  '[Expenses] Delete Request',
  props<{ expenseId: number }>(),
);
export const deleteSuccess = createAction('[Expenses] Delete Success');
export const deleteFailure = createAction(
  '[Expenses] Delete Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Expenses] Reset State');
export const resetDetails = createAction('[Expenses] Reset Details State');
